import type { NextPage } from 'next';
import React from 'react';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useToggle } from '@react-hookz/web';

import { SideBar } from 'src/components/SideBar';
import { useAuth } from 'src/util/use-auth';
import { Loader } from 'src/components/Loader';

const Login: NextPage = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation('login');
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useToggle();
  const [errorDetail, setErrorDetail] = React.useState<any>(null);

  const onLogin = (values: any) => {
    setIsLoading(true);
    login(values.username, values.password)
      .then(() => {
        setErrorDetail(null);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        if (error.response && error.response.data?.field_errors) {
          form.setFields(
            Object.keys(error.response.data.field_errors).map(
              (fieldName: string) => ({
                name: fieldName,
                value: values[fieldName],
                errors: [error.response.data.field_errors[fieldName]],
              })
            )
          );
        }
        if (error.response && error.response.data?.description) {
          if (error.response.data.code === 'authentication_failed') {
            setErrorDetail({
              title: t('Authentication Failed'),
              description: t(
                'Account not found or password incorrect, request a new password or contact Technical support (support@businessradar.com)'
              ),
            });
          } else {
            setErrorDetail({
              title: error.response.data.title,
              description: error.response.data.description,
            });
          }
        }
      });
  };

  return (
    <SideBar
      title={t('Login')}
      description={
        <ul style={{ listStyle: 'none' }}>
          {/*<li style={{ marginBottom: 10 }}>*/}
          {/*  <Link href="/register" passHref>*/}
          {/*    <a style={{ color: '#fff' }}>{t('Register')}</a>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li style={{ marginBottom: 10, color: '#fff' }}>
            <Link href="/account/password-reset" passHref>
              <a style={{ color: '#fff' }}>{t('Password Forgotten')}</a>
            </Link>
          </li>
        </ul>
      }
    >
      <Loader loading={isLoading}>
        {errorDetail ? (
          <Alert
            message={errorDetail.title}
            description={errorDetail.description}
            className={'gx-mb-4'}
            type="error"
            showIcon
          />
        ) : null}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onLogin}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={'gx-signin-form gx-form-row0'}
        >
          <Form.Item
            label={t('Username')}
            name="username"
            data-cy="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Password')}
            name="password"
            data-cy="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
            data-cy="remember"
          >
            <Checkbox>{t('Remember Me')}</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              data-cy="login-button"
              className="gx-mb-0"
              htmlType="submit"
            >
              {t('Submit')}
            </Button>
          </Form.Item>
        </Form>
      </Loader>
    </SideBar>
  );
};

// @ts-ignore
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'login'])),
  },
});

export default Login;
